<template>
  <transition name="test">
    <a-steps
      v-show="show"
      class="feedback-records"
      progress-dot
      :current="feedbackFileList.length"
      direction="vertical"
    >
      <a-step v-for="(item, index) of feedbackFileList" :key="index">
        <template v-slot:title>
          <div class="create-date">{{ moment(item.createDate).format($t('dateFormat.e')) }}</div>
        </template>
        <template v-slot:description>
          <!-- <item ref="item" :show="showItem" :content="item.feedback" :width="width" /> -->
          <div class="deliver-date">
            <div class="deliver-date-label">修改意见：</div>
            <div class="deliver-date-value">
              <div class="mb-1">{{ item.feedback }}</div>
              <div class="ant-steps-item-description-download">
                <div
                  v-for="(_item, index) of item.feedbackFileList"
                  :key="index"
                  class="ant-steps-item-description-download-item"
                >
                  <span class="text">{{ _item.name }}</span>
                  <img
                    class="download-icon pointer"
                    @click="download(_item.url)"
                    src="@/assets/image/feedbackRecord-download.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="deliver-date">
            <div class="deliver-date-label">修改稿交付日期：</div>
            <div class="deliver-date-value">{{ item.feedbackDueDate }}</div>
          </div>
        </template>
      </a-step>
    </a-steps>
  </transition>
</template>

<script>
import moment from 'moment'
// import item from './item'
import { downloadUrl } from '@/utils/utils'

export default {
  name: 'FeedbackRecords',
  components: {
    // item,
  },
  data() {
    return {
      moment,
      scrollWrapperHeight: 0,
      steps: [],
      showToolTip: {},
      showItem: false,
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    feedbackFileList: {
      type: Array,
      default: () => [],
    },
    width: {
      type: String,
      default: '98%',
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.showItem = true
      } else {
        this.showItem = false
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    download(url) {
      console.log(`url`, url)

      if (url) {
        downloadUrl(url)
      } else {
        this.$message.error('暂无文件链接')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.feedback-records {
  background-image: url('~@/assets/image/feedbackRecords-bg.png');
  padding: 12px;
  .create-date {
    width: 120px;
    line-height: 13px;
    font-size: 12px;
    color: #8895ab;
  }
  /deep/.ant-steps-item-description {
    padding-bottom: 12px;
    .deliver-date {
      font-family: Microsoft YaHei;
      font-weight: 400;
      // line-height: 13px;
      color: #46546c;
      margin: 8px 0;
      font-size: 13px;
      display: flex;
      &-label {
        width: 105px;
        display: inline-block;
        text-align: right;
      }
      &-value {
        width: calc(100% - 105px);
        color: #8895ab;
      }
    }
    &-content {
      font-size: 11px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #8895ab;
      height: 13px;
      line-height: 13px;
      // width: 253px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-download {
      &-item {
        // display: inline-block;
        .text {
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 13px;
          color: #4080ff;
        }
        .download-icon {
          width: 12px;
          height: 12px;
          display: inline-block;
          margin-left: 5px;
          margin-right: 10px;
        }
      }
    }
  }
  ::v-deep {
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
      background-color: #eeeeee;
    }
    .ant-steps-icon {
      display: inline-block;
      width: 12px;
      height: 12px;
      background: rgba(64, 128, 255, 0.2);
      box-shadow: 0px 3px 63px rgba(0, 0, 0, 0.16);
      border-radius: 50%;
    }
    .ant-steps-icon > .ant-steps-icon-dot {
      width: 8px;
      height: 8px;
      background-color: #4080ff !important;
      opacity: 1;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    .ant-steps-item-icon {
      margin-left: -2.5px;
      margin-top: 1px;
    }
    .ant-steps-item-content {
      width: auto;
    }

    .ant-steps-item-title {
      width: 120px;
    }
    .ant-steps-item-description {
      flex: 1;
      width: 94%;
    }

    .ant-steps-item-operation {
      cursor: pointer;
    }
    .ant-steps-vertical .ant-steps-item-content {
      min-height: none;
    }
  }
}

.test-enter,
.test-leave-to {
  opacity: 0;
}
.test-enter-to,
.test-leave {
  opacity: 1;
}
.test-enter-active,
.test-leave-active {
  transition: all 0.4s;
}
</style>
